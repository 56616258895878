<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      size="lg"
      centered
      :title="isCreated ? 'Thêm bộ tiêu chí' : 'Cập nhật bộ tiêu chí'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="factAdmissionCriteriaTypeId">
              <template v-slot:label>
                Loại tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại tiêu chí"
                rules="required"
              >
                <v-select
                  v-model="targetItem.factAdmissionCriteriaTypeId"
                  :options="dataAdmissionCriteriaTypes"
                  :reduce="option => option.value"
                  @input="selectAdmissionCriteriaTypeId"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="admissionRoundId">
              <template v-slot:label>
                Đợt tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  @input="readAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                  @input="selectAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            v-show="checkTypeCriteriaSet"
          >
            <b-form-group label-for="admissionMajorIds">
              <template v-slot:label>
                Ngành tuyển sinh
              </template>
              <v-select
                v-model="targetItem.admissionMajorIds"
                multiple
                :options="dataListMajorByAdmissionForm"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            v-show="checkTypeCriteriaSet"
          >
            <b-form-group label-for="admissionBlockIds">
              <template v-slot:label>
                Tổ hợp môn xét tuyển
              </template>
              <v-select
                v-model="targetItem.admissionBlockIds"
                multiple
                :options="dataAdmissionBlock"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên bộ tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên bộ tiêu chí"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Tên bộ tiêu chí"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã bộ tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã bộ tiêu chí"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Nhập mã bộ tiêu chí"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Chi tiết bộ tiêu chí"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="targetItem.description"
                name="description"
                placeholder="Chi tiết bộ tiêu chí"
                rows="3"
                max-rows="5"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'

export default {
  name: 'Save',
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        id: null,
        name: '',
        code: '',
        organizationId: getUser().orgId,
        factAdmissionCriteriaTypeId: null,
        description: '',
        status: Flag.ACTIVE,
        orderNo: 1,
        admissionRoundId: null,
        admissionFormId: null,
        admissionMajorIds: [],
        admissionBlockIds: [],
      },
      required,
      dataAdmissionBlock: [],
      checkTypeCriteriaSet: false,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionCommittee/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListMajorByAdmissionForm: 'admissionMajor/dataListMajorByAdmissionForm',
      dataAdmissionCriteriaTypes: 'factAdmissionCriteriaType/dataAdmissionCriteriaTypes',
      dataAdmissionBlockGetAll: 'factAdmissionBlock/admissionBlockGetAll',
      codeById: 'factAdmissionCriteriaType/codeById',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createCriteriaSets: 'criteriaSet/createCriteriaSets',
      updateCriteriaSets: 'criteriaSet/updateCriteriaSets',
      getCodeById: 'factAdmissionCriteriaType/getCodeById',
      getMajorByAdmissionFormId: 'admissionMajor/getMajorByAdmissionFormId',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getAdmissionCriteriaTypes: 'factAdmissionCriteriaType/getAdmissionCriteriaTypes',
      getDataAdmissionBlockGetAll: 'factAdmissionBlock/getAdmissionBlockStatusAndDelFlag',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getAdmissionCriteriaTypes()
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      await this.getDataAdmissionBlockGetAll()
      this.dataAdmissionBlock = this.dataAdmissionBlockGetAll
      if (this.item) {
        this.targetItem = { ...this.item }
        await this.getCodeById(this.targetItem.factAdmissionCriteriaTypeId)
        if (this.codeById === 'TCĐGĐV') {
          this.checkTypeCriteriaSet = true
        } else {
          this.checkTypeCriteriaSet = false
        }
        await this.readAdmissionForm(this.targetItem.admissionRoundId)
        await this.getMajorByAdmissionFormId({ admissionFormId: this.targetItem.admissionFormId })
        if (this.targetItem.admissionMajorIds.length > 0) {
          const arrAdmissionMajorId = this.targetItem.admissionMajorIds.split(',')
          const temp = arrAdmissionMajorId.filter((item, index) => arrAdmissionMajorId.indexOf(item) === index)
          this.targetItem.admissionMajorIds = temp.map(str => parseInt(str, 10))
        } else {
          await this.getMajorByAdmissionFormId({ admissionFormId: this.targetItem.admissionFormId })
        }
        if (this.targetItem.admissionBlockIds.length > 0) {
          const arrAdmissionBlockId = this.targetItem.admissionBlockIds.split(',')
          const temp = arrAdmissionBlockId.filter((item, index) => arrAdmissionBlockId.indexOf(item) === index)
          this.targetItem.admissionBlockIds = temp.map(str => parseInt(str, 10))
        } else {
          await this.getDataAdmissionBlockGetAll()
        }
      } else {
        this.targetItem = { ...this.targetItem, organizationId: getUser().orgId }
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: this.targetItem.admissionRoundId })
      }
    },
    async selectAdmissionCriteriaTypeId(id) {
      await this.getCodeById(id)
      if (this.codeById === 'TCĐGĐV') {
        this.checkTypeCriteriaSet = true
      } else {
        this.checkTypeCriteriaSet = false
      }
    },
    async selectAdmissionForm() {
      await this.getMajorByAdmissionFormId({ admissionFormId: this.targetItem.admissionFormId })
    },
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        id: null,
        name: '',
        code: '',
        organizationId: getUser().orgId,
        factAdmissionCriteriaTypeId: null,
        description: '',
        status: Flag.ACTIVE,
        orderNo: 1,
        admissionRoundId: null,
        admissionFormId: null,
        admissionMajorIds: [],
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createCriteriaSets({
              criteriaSet: {
                name: this.targetItem.name,
                code: this.targetItem.code,
                status: this.targetItem.status,
                factAdmissionCriteriaTypeId: this.targetItem.factAdmissionCriteriaTypeId,
                organizationId: this.targetItem.organizationId,
                description: this.targetItem.description,
              },
              admissionRoundId: this.targetItem.admissionRoundId,
              admissionFormId: this.targetItem.admissionFormId,
              admissionMajorIds: this.targetItem.admissionMajorIds,
              admissionBlockIds: this.targetItem.admissionBlockIds,
            })
            : await this.updateCriteriaSets({
              id: this.targetItem.id,
              criteriaSet: {
                id: this.targetItem.id,
                name: this.targetItem.name,
                code: this.targetItem.code,
                status: this.targetItem.status,
                factAdmissionCriteriaTypeId: this.targetItem.factAdmissionCriteriaTypeId,
                organizationId: this.targetItem.organizationId,
                description: this.targetItem.description,
              },
              admissionRoundId: this.targetItem.admissionRoundId,
              admissionFormId: this.targetItem.admissionFormId,
              admissionMajorIds: this.targetItem.admissionMajorIds.length === 0 ? [] : this.targetItem.admissionMajorIds,
              admissionBlockIds: this.targetItem.admissionBlockIds.length === 0 ? [] : this.targetItem.admissionBlockIds,
            })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
