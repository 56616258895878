<template>
  <b-form>
    <CriteriaAccess
      v-if="check"
      :criteria-set-id="criteriaSetId"
      :criteria="criteria"
    />
    <CriteriaPriority
      v-if="!check"
      :criteria-set-id="criteriaSetId"
      :criteria="criteria"
    />
  </b-form>
</template>
<script>
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import CriteriaPriority from '@/views/admission/criteria/CriteriaPriority.vue'
import CriteriaAccess from '@/views/admission/criteria/CriteriaAssess.vue'

export default {
  name: 'CriteriaSave',
  directives: {
    Ripple,
  },
  components: {
    CriteriaPriority,
    CriteriaAccess,
  },
  props: {
    criteriaSetId: {
      type: Number,
      default: null,
    },
    criteria: {
      type: Object,
      default: () => undefined,
    },
    check: {
      type: Boolean,
      default: null,
    },
    admissionFormId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
