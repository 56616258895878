<template>
  <b-card-code
    no-body
  >
    <b-table
      responsive="sm"
      :items="items"
      style="text-align: center;"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'

export default {
  components: {
    BCardCode,
    BTable,
  },
  props: {
    criteriaSetId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      dataByCriteriaId: 'admissionLanguageScore/dataByCriteriaId',
    }),
  },
  async created() {
    await this.getInitData()
  },
  methods: {
    ...mapActions({
      getDataByCriteriaId: 'admissionLanguageScore/getDataByCriteriaId',
    }),
    transformData(data) {
      return data.map(item => ({
        Loại_chứng_chỉ: item.languageCertificateName,
        Điêm_chấp_nhận: item.acceptScore,
        Số_năm_thi: item.year,
      }))
    },
    async getInitData() {
      const params = {
        criteriaSetId: this.criteriaSetId
      }
      await this.getDataByCriteriaId(params)
      this.items = this.transformData(this.dataByCriteriaId)
    },
  },
}
</script>
